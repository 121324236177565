#dvuserreset .form-control{
    border-color: #055c02;
    border-radius: 7px;
}
/* #dvuserreset .otp{
    width: 20% !important;
} */
.button2{
    margin-left: 200px !important;
    background: linear-gradient(to right bottom,rgb(119, 202, 119),rgb(45, 132, 38));
}
.button1{
    width: 100%;
    background: linear-gradient(to right bottom,rgb(119, 202, 119),rgb(45, 132, 38));
}
#dvuserreset .button2{
    margin-left: 40%;
    border-radius: 20px;
}

.btn-outline-danger{
    border-radius: 30px;
}

#dvuforgotuser .form-control{
    border-color: green;
}
#dvuforgotuser .btn{
    background: linear-gradient(to right bottom,rgb(119, 202, 119),rgb(45, 132, 38));
    color: white;
    margin-left: 80%;
}
#forgot{
    margin-top: 10%;
}

#dvuforgotsuccess .text{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 
}
#dvuforgotsuccess div{
   margin-top: 13%;
}