#dvuserregister .card {
    border: 0;
    border-radius: 0px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.03);
    box-shadow: 0 2px 3px rgba(0,0,0,0.03);
    -webkit-transition: .5s;
    transition: .5s;
    margin-left: 20%;
}

.padding {
    padding: 3rem !important
}

body {
    background-color: #ffffff
}

#dvuserregister .card-title {
    /* /* font-size: 25px;
    font-family: Roboto,sans-serif; */
     
    /* line-height: 1.5;
     margin-bottom: 0; 
     padding: 15px 20px;
    border-bottom: 1px solid rgba(77,82,89,0.07); */ 
    /* margin-left: 10px; */
    font-weight: 600;
}
#dvuserregister .top{
    margin-bottom: 10%;
}

.fw-400 {
    font-weight: 400 !important;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
   
}
#dvuserregister .card-body{
    margin-right: 20%;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-body>*:last-child {
    margin-bottom: 0;
}


.btn-bold {
    font-family: Roboto,sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
}

.btn-primary:hover {
    background-color: #52d3c7;
    border-color: #52d3c7;
    color: #fff;
}

.btn:hover {
    cursor: pointer;
}

.form-control:focus {
    border-color: #83e0d7;
    color: #4d5259;
    -webkit-box-shadow: 0 0 0 0.1rem rgba(51,202,187,0.15);
    box-shadow: 0 0 0 0.1rem rgba(51,202,187,0.15);
}

.custom-radio {
    cursor: pointer;
}

.custom-control {
    display: -webkit-box;
    display: flex;
    min-width: 18px;
}


#dvuserregister .form-control{
    border-color:rgb(20, 114, 17);
    border-radius: 7px;
    color: #8b95a5;
    padding: 5px 12px;
    font-size: 14px;
    line-height: inherit;
    -webkit-transition: 0.2s linear;
    transition: 0.2s linear;
    width: 110%;

}

#dvuserregister .btn-success{
    width: 110%;
    background: linear-gradient(to left top, rgb(66, 124, 66),rgb(121, 238, 135));
}

#dvuserregister .btn-success:hover{
    transform: translateX(5px);
}
